const contact = 
    {
        FirstName: "Lox",
        LastName: "Lisa",
        Email: "lisa.frontdev@gmail.com",
        Github:"https://github.com/LilouCode",
        Place: "remote",
        Linkedin: "https://linkedin.com/in/lisa-lox-frontdev",
        Twitter: 'https://twitter.com/LilouCode',
        
    }

export default contact