const portfolio = [
  {
    id: 2,
    title: "Booki",
    url: "https://github.com/LilouCode/LisaLox_2_05102021",
    descrition: "Integration of a template website HTML5 and CSS3",
    toDo: [
      "Implement a responsive interface",
      "Integrate content according to a template",
    ],
    picture: "./projects/id2.png",
    source: "gitHub",
    filter:"dev"
  },
  {
    id: 3,
    title: "Ohmyfood!",
    url: "https://github.com/LilouCode/LisaLox_3_10112021",
    descrition: "Liven up a web page with CSS3 animations",
    toDo: [
      "Set up a navigation structure for a website",
      "Set up a Front-End environment",
      "Ensure the graphic consistency of a website",
      "Implement advanced graphical CSS effects",
      "Use a version control system for project tracking and hosting",
    ],
    picture: "./projects/id3.png",
    source: "gitHub",
    filter:"dev"
  },
  {
    id: 4,
    title: "GameOn",
    url: "https://github.com/LilouCode/GameOn-website-FR",
    descrition: "Programming in vanilla JavaScript",
    toDo: ["Set up a navigation structure for a website"],
    picture: "./projects/id4.png",
    source: "gitHub",
    filter:"dev"
  },
  {
    id: 6,
    title: "FishEye",
    url: "https://github.com/LilouCode/LisaLox_6_03012022",
    descrition: "Create an accessible website for a photography platform",
    toDo: [
      "Ensuring the accessibility of a website",
      "Handling application events with vanilla JavaScript",
      "Develop a modular web application using design patterns",
      "Write maintainable JavaScript code",
    ],
    picture: "./projects/id6.png",
    source: "gitHub",
    filter:"dev"
  },
  {
    id: 7,
    title: "Les petits plats",
    url: "https://github.com/LilouCode/LisaLox_7_28022022",
    descrition: "Develop a search algorithm in JavaScript",
    toDo: ["Develop an algorithm to solve a problem"],
    picture: "./projects/id7.png",
    source: "gitHub",
    filter:"dev"
  },
  {
    id: 1,
    title: "Nathalie Da Costa",
    url: "https://www.nathaliepsychogenealogie.com",
    descrition: "Web design",
    toDo: [
      "Create and Publish a Website with Wix",
      "Carry out a project using an agile methodology",
      "Create the layout and design for a client",
      "Create a brand identity",
      "Identify application needs and write specifications",
    ],
    picture: "./projects/id81.png",
    source: "web",
    filter:"design"
  },
  {
    id: 9,
    title: "Billed",
    url: "https://github.com/LilouCode/LisaLox_9_29032022",
    descrition: "Debug and test an HR SaaS",
    toDo: [
      "Write unit tests with JavaScript",
      "Debug a web application with the Chrome Debugger",
      "Write an end-to-end manual test plan",
      "Write integration tests with JavaScript",
    ],
    picture: "./projects/id9.png",
    source: "gitHub",
    filter:"dev"
  },
  {
    id: 10,
    title: "Learn@Home",
    url: "https://www.figma.com/file/NdF6JYQpe1flcSPohBS9yq/Learn%40Home?node-id=0%3A1",
    descrition: "Define the needs for a tutoring app",
    toDo: [
      "Contribute to a project using an agile methodology",
      "Create layout and design for a client",
      "Identify the needs of the application from the specification",
      "Developing a technical solution for a client",
    ],
    picture: "./projects/id10.png",
    source: "Figma",
    filter:"design"
  },
  {
    id: 11,
    title: "Kasa",
    url: "https://github.com/LilouCode/lisalox_11_02052022",
    descrition: "Develop a web application with React and React Router",
    toDo: [
      "Developing routes for a web application with React Router",
      "Initialize a web application with a framework",
      "Create components with React",
    ],
    source: "gitHub",
    picture: "./projects/id11.png",
    filter:"dev"
  },
  {
    id: 12,
    title: "SportSee",
    url: "https://github.com/LilouCode/LisaLox_12_19052022",
    descrition: "Build an analytics dashboard with React",
    toDo: [
      "Ensuring the data quality of an application",
      "Interact with a web service",
      "Develop advanced graphical elements using JavaScript libraries",
      "Produce technical documentation for an application",
    ],
    picture: "./projects/id12.png",
    source: "gitHub",
    filter:"both"
  },
  {
    id: 13,
    title: "Argent Bank",
    url: "https://github.com/LilouCode/lisalox_13_13062022",
    descrition: "Use an API for a bank user account with React",
    toDo: [
      "Implement a state manager in a React application",
      "Interact with an API",
      "Design an API",
      "Authenticate to an API",
    ],
    source: "gitHub",
    filter:"dev"
  },
  {
    id: 14,
    title: "Wealth Health",
    url: "https://github.com/LilouCode/lisalox_14_18072022",
    descrition: "Switch a jQuery library to React",
    toDo: [
      "Analyze the performance of a web application",
      "Deploy a front-end application",
      "Create and publish React component library",
      "Redesign of an application to reduce technical debt",
    ],
    picture: "./projects/id14.png",
    source: "gitHub",
    filter:"both"
  },
  {
    id: 8,
    title: "Luiggi Coiffeur",
    url: "https://www.luiggicoiffeur.com",
    descrition: "Web design",
    toDo: [
      "Create and Publish a Website with Wix",
      "Carry out a project using an agile methodology",
      "Create the layout and design for a client",
      "Create a brand identity",
      "Identify application needs and write specifications",
    ],
    picture: "./projects/id15.png",
    source: "web",
    filter:"design"
  },
  {
    id: 16,
    title: "Arce",
    url: "https://www.arcepf.com",
    descrition: "Web design",
    toDo: [
      "Create and Publish a Website with Wix",
      "Carry out a project using an agile methodology",
      "Create the layout and design for a client",
      "Create a brand identity",
      "Identify application needs and write specifications",
    ],
    picture: "./projects/id16.png",
    source: "web",
    filter:"design"
  },
  {
    id:17,
    title: "Lisa Frontdev",
    url:"https://github.com/LilouCode/my-portfolio",
    descrition: "Design and development with React",
    toDo: [
      "Design process",
      "Developing with React",
      "Develop a low carbon app",
      "Deploy on Netlify"
    ],
    source: "gitHub",
    picture:"./projects/id17.png",
    filter:"both"
  }
];
export default portfolio;
